<template>
	<div class="row mt-3">
		<label for="cmep" class="col-sm-4 col-form-label">
			<div class="row">
				<div class="col-12">
					{{ $t('monte.cmep') }}
					<span class="float-right" v-if="cmep && cmep.tiers_id">
						<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: cmep.tiers_id }}">
							<font-awesome-icon :icon="['fal', 'link']" />
						</router-link>
					</span>
				</div>
				<div class="col-12" v-if="cmep && cmep.tiers_id">
					<TagsManagement :fk="cmep.tiers_id" type="tiers" />
				</div>
			</div>
		</label>
		<div class="col-sm-8">
			<!-- <e-select
				v-model="cmep_model"
				track-by="tiers_id"
				label="tiers_rs"
				:placeholder="labelTitleCmep"
				:selectedLabel="selectedLabelCmep"
				:options="tiers_tab"
				:allow-empty="true"
				:show-labels="false"
				:disabled="disabled"
				:loading="loadingTiers"
				:class="{ 'is-invalid': errors && errors.indexOf('cmep') > -1 }"
			></e-select> -->
			<SearchTiersv2
				:tiers_selected.sync="cmep_model"
				:preselected="cmep_model"
				:disabled="disabled_cmep"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'ChangementCMEP',
		mixins: [],
		props: [
			"contract_id",
			"tiers_tab",
			"disabled",
			"cmep",
		],
		data () {
			return {
				cmep_model: null,
				loadingTiers: false,
				errors: [],
				disabled_cmep: false,
				labelTitleCmep: this.getTrad("monte.rechercher_cmep"),
				selectedLabelCmep: this.getTrad("global.selected_label"),
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.cmep_model = this.cmep
				if(this.disabled) {
					this.disabled_cmep = this.disabled
				}
			},
		},
		watch: {
			cmep_model(val){
				this.$emit("update:cmep", val)
			},
			cmep(val){
				this.cmep_model = val
			},
			disabled(val) {
				if(this.disabled != null) {
					this.disabled_cmep = val
				}
			}
		},

		components: {
			SearchTiersv2 : () => import('@/components/Contract/SearchTiersv2'),
            TagsManagement: () => import('@/components/TagsManagement')
		}
	}

</script>